<template>
	<div class="detail-container">
		<div class="breadcrumb">
			<div class="item" v-for="(item,index) in breadcrumbList" :key="index">
				<div class="name" v-if="index<breadcrumbList.length-1">
					<router-link :to="{ path: item.path }" class="link">{{item.name}}</router-link>
					<div>&gt;</div>
				</div>
				<div class="txt" v-else>{{item.name}}</div>
			</div>
		</div>
		<div class="detail-content">
			<div class="title">{{title}}</div>
			<div class="publish-info flex flex-center">
				<div class="publish-items">发布时间：{{newsInfo.update_time | stringData}}</div>
				<!-- <div class="publish-items">编辑：小张</div> -->
				<div class="publish-items">浏览：{{newsInfo.views}}</div>
			</div>
			<div class="main" v-html="newsInfo.content"></div>
		</div>
		<div class="detail-slide">
			<div class="module information">
				<div class="title">
					<img src="@/assets/icons/icon-test-white.png" class="title-img">
					<div class="txt">考试安排</div>
					<div class="more" @click="onRoute('ExamArrangement')"> &gt;&gt; </div>
				</div>
				<div class="list">
					<div class="item" v-for="(item,index) in examSchedule" v-if="index < 6" :key="'exam'+index" @click="onRoute('NewsDetail',item.article_id)">
						<div class="txt">{{item.title}}</div>
						<div class="data">{{item.update_time | stringData }}</div>
					</div>
				</div>
			</div>
			<div class="module projectPolicy">
				<div class="title">
					<img src="@/assets/icons/icon-project-white.png" class="title-img">
					<div class="txt">项目政策</div>
					<div class="more" @click="onRoute('ProjectPolicy')"> &gt;&gt; </div>
				</div>
				<div class="list">
					<div class="item" v-for="(item,index) in projectPolicy" v-if="index < 6" :key="'exam'+index" @click="onRoute('NewsDetail',item.article_id)">
						<div class="txt">{{item.title}}</div>
						<div class="data">{{item.update_time | stringData }}</div>
					</div>
				</div>
			</div>
			<div class="module problemSolving">
				<div class="title">
					<img src="@/assets/icons/icon-question-white.png" class="title-img">
					<div class="txt">问题解答</div>
					<div class="more" @click="onRoute('ProblemSolving')"> &gt;&gt; </div>
				</div>
				<div class="list">
					<div class="item" v-for="(item,index) in problemSolving" v-if="index < 6" :key="'exam'+index" @click="onRoute('NewsDetail',item.article_id)">
						<div class="txt">{{item.title}}</div>
						<div class="data">{{item.update_time | stringData }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import { mapGetters } from 'vuex';
// import { formatRichText } from '@/utils/formatRich.js'
export default {
	filters: {
    stringData: function (date) {
      return moment(date * 1000).format("YYYY-MM-DD");
    },
  },
	data() {
		return {
			newsInfo:{},
			examSchedule:[],
			projectPolicy:[],
			problemSolving:[],
			title:'',
		};
	},
	computed: {
		...mapGetters(['breadcrumbList']),		
		// showinArray: function() {
		// 	return this.problemSolving.filter((index) => {
		// 		return index < 6
		// 	})
		// } 
	},
	watch: {
    '$route' (to, from) {
      let id = this.$route.query.id
			this.getNewsInfo(id,this.$route.query.isPreview);		
			this.getFolders();
    },
		
	},
	created() {
		let id = this.$route.query.id
		// console.log(this.$route)
		this.getNewsInfo(id,this.$route.query.isPreview)
		this.getFolders();
		
	},
	methods:{
		getFolders() {
			this.$http
			.get('/api/web/index/getNoticeList')
			.then((res) => {
				this.examSchedule = res.data.examSchedule,
				this.projectPolicy = res.data.projectPolicy,
				this.problemSolving = res.data.problemSolving
			})
		},
		getNewsInfo(id,isPreview) {	
				this.$http
				.get('/api/web/news/getNewsInfo?article_id='+id+'&isPreview='+isPreview)
				.then((res) => {	
					if (res.data.newsInfo == false) {
						this.$router.push({name:'Notfound'})					
					}else{
						this.newsInfo = res.data.newsInfo;	
						this.title = this.newsInfo.title;
						// this.newsInfo.content=				
					}				
				})
					
		},
		onRoute(name,id){
			this.$router.push({name,query:{id}})
		}
	},
	metaInfo() {
    return {	
      title: this.title
    };
  },
};
	
</script>

<style lang='less' scoped>
	.detail-container{
		width: 1300px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		padding: 30px 0;
		border-radius: 10px;
		.breadcrumb{
			width: 100%;
			display: flex;
			align-items: center;
			.item{
				margin-bottom: 20px;
				font-size: 12px;
				color: #999;
				.name{
					display: flex;
					align-items: center;
				}
				.link{
					padding: 0 2px;
					color: #999;
					text-decoration: none;
				}
				.txt{
					padding: 0 2px;
				}
			}
		}
		.detail-content{
			width: 1000px;
			margin: 0 30px 0 0;
			padding: 30px;
			background: #fff;
			border-radius: 10px;
			.title{
				width: 100%;
				font-size: 24px;
				font-weight: bold;
				text-align: center;
				color: #333333;
			}
			/deep/.main{
				padding-top: 20px;
				line-height: 36px;
				margin: 0 auto 15px;
				overflow: hidden;
				.video-js{
					width: 800px;
					height: auto;
					object-fit: fill;
				}
				section>span{
					display: inline-block;
					text-align: left;
				}
				img{
					max-width: 100%;
				}
			}
			.publish-info{
				padding: 20px 0;
				margin-bottom: 50px;
				border-bottom: 2px solid #f5f5f5;
				color: #999;
				.publish-items{
					font-size: 15px;
					color: #666666;
					line-height: 32px;
					margin: 0 20px;
				}
			}
			p{
				font-size: 16px;
				color: #666666;
				line-height: 32px;
				margin: 0;
				margin-bottom: 30px;
				text-align: left;
				text-indent: 32px;
				img{
					display: block;
					margin: 0 auto 30px;
					max-width: var(--container-width);
				}
			}
		}
		.detail-slide{
			width: 270px;
			.module{
				overflow: hidden;
				background: #fff;
				margin-bottom: 30px;
				border-radius: 6px;
				.title{
					height: 68px;
					padding: 8px 19px;
					font-size: 18px;
					font-weight: bold;
					color: #ffffff;
					display: flex;
					align-items: center;
					.title-img{
						width: 32px;
						height: 32px;
						margin-right: 10px;
					}
					.more{
						cursor: pointer;
						margin-left: auto;
						font-weight: 400;
					}
				}
				.list{
					padding: 10px 19px;
				}
				.item{
					display: flex;
					align-items: center;
					padding: 6px 0;
					.txt{
						width: calc(100% - 100px);
						font-size: 14px;
						color: #666;
						text-align: left;
						white-space: nowrap;
						text-overflow: ellipsis;
						cursor: pointer;
						overflow: hidden;
					}
					.data{
						width: 100px;
						font-size: 12px;
						color: #999;
						text-align: right;
					}
				}
			}
			.information .title {
				background: #ffd000;
			}
			.projectPolicy .title {
				background: #ff6c00;
			}
			.problemSolving .title {
				background: #e80c0a;
			}
		}
	}
</style>
